import React from "react";
import "./home.scss";
import Header from "../../section/header/header.jsx";
import Banner from "../../section/Banner/banner.jsx";
import About from "../../section/About/about.jsx";
import Promo from "../../section/Promo/promo.jsx";
import Fasilitas from "../../section/Fasilitas/fasilitas.jsx";
import Rumah from "../../section/Rumah/rumah.jsx";
import Lokasi from "../../section/Lokasi/lokasi.jsx";
import Footer from "../../section/Footer/footer.jsx";
import Ruko from "../../section/Ruko/ruko.jsx";
import Tombol from "../../Media/tombol.webp";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ScrollToHashElement from "./ScrollToHashElement.js";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287719120114&text=Halo%20Marketing%20Citraland,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Citraland%20Tallasa%20https://citraland-tallasa.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="home">
      <ScrollToHashElement />
      <Header />
      <Banner />
      <About />
      <Promo />
      <Fasilitas />
      <Rumah />
      <Ruko />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={Tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
      <div className="wamobile">
        <button onClick={fungsiganteng} className="mobile">
          <div>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp Sekarang
          </div>
        </button>
      </div>
    </div>
  );
};

export default home;
