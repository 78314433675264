import React from "react";
import "./ruko1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CentarumAimages from "./CentarumA.js";
import CentarumBimages from "./CentarumB.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const ruko1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const CentarumAwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287719120114&text=Halo%20Marketing%20Citraland,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Ruko%20Grand%20Centarum%20%20A%20https://citraland-tallasa.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const CentarumBwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287719120114&text=Halo%20Marketing%20Citraland,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Ruko%20Grand%20Centarum %20B%20https://citraland-tallasa.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard1">
        <div className="card">
          <Slider {...settings}>
            {CentarumAimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Grand Centarum A</div>
              <span>Ruko 3 Lantai</span>
            </div>
            <div className="cicilan">
              <div className="angka">27</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color="#0f1c3f" icon={faArrowsUpDownLeftRight} />
            <span> : 90m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faHouse} />
            <span> : 235m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faLayerGroup} />
            <span>: 3</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faShower} />
            <span>: 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={CentarumAwa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {CentarumBimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Grand Centarum B</div>
              <span>Ruko 3 Lantai</span>
            </div>
            <div className="cicilan">
              <div className="angka">27</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color="#0f1c3f" icon={faArrowsUpDownLeftRight} />
            <span> : 90m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faHouse} />
            <span> : 240m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faLayerGroup} />
            <span>: 3</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faShower} />
            <span>: 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={CentarumBwa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ruko1;
